@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.error {
  color: red;
}

.wrap {
  height: 100%;
}

.flex-column {
  flex-direction: column !important;
}

.d-flex {
  display: flex !important;
}

.input-group {
  width: 100%;
  border: none !important;
  border-bottom: 1px solid #eee !important;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-collapse: separate;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-append,
.input-group-prepend {
  display: flex;
  border-right: 1px solid;
  padding-right: 10px;
  cursor: pointer;
}

.mobile-group .MuiInputBase-input {
  padding-left: 10px;
}

.MuiFormLabel-root {
  margin-bottom: 7px;
  font-size: 14px !important;
  color: #717171 !important;
  font-weight: 600 !important;
  display: inline-block;
}

.chip-tag {
  background: #E4EDF4 !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  color: #717171 !important;
}

.Mui-disabled.MuiChip-deletable .MuiChip-deleteIcon {
  display: none;
}

[data-rbd-droppable-id="tableDrop"] {
  width: inherit
}

/* call popup timer */
@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }

  to {
    stroke-dashoffset: 113px;
  }
}